import React, { FC } from "react"
// import { Link } from "gatsby"
import Head from "../../components/head"
import Header from "../../components/header"
import Footer from "../../components/footer"
import styles from "../../styles/global.css"
import contact from "../../styles/media_page.css"
import media from "../../styles/media.css"

export default () => (
  <>
    <Header titleText="Media - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
        <div className="clear_both"></div>
        <div className="loginheader_div">
          <div className="loginheader">
            <div className="loginheaderlist_div">
              <img
                alt="icon doller"
                src="/sites/default/files/icon_doller.png"
              />
              <a rel="" href="/pricing/">
                Pricing
              </a>
            </div>
            <div className="loginheaderlist_div">
              <img alt="icon trial" src="/sites/default/files/icon_trial.png" />
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                target="_blank"
              >
                Free Trial
              </a>
            </div>

            <div className="loginheaderlist_div">
              <img alt="user" src="/sites/default/files/user.png" />
              <span className="login_span">
                <a rel="" href="javascript:;">
                  Log In
                </a>
                <ul className="login_ul_son">
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://user.happymangocredit.com"
                      target="_blank"
                    >
                      Individual/Business
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://institution.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Institutions
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://advisor.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Coaches
                    </a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Head />

      {/* <div className="banner_contact">
        <img
          className="contact_img"
          src="/images/fresh-leaves.jpg"
          alt="banner"
        />
        <h1 className="bannerinfo">Mango Slice Blog</h1>
      </div> */}

      <div className="media">





<div id="preface" className="block">
  <div className="region region-preface">
    <div id="block-views-security-block" className="block block-views blue we-believe">
      <div className="inner">
        <div className="content">
          <div className="view view-security view-id-security view-display-id-block view-dom-id-09b08ef9d2ef556de55f0586d9ed9143">
            <div className="view-content">
              <div className="item">
                <div>
                  <div className="title">
                    <div
                      style={{
                        "margin-top": "-10px",
                        "text-align": "left",
                        "font-size": "28px",
                        "font-weight": "bold",
                      }}
                    >
                      <h3>Good Profits for Good Loans</h3>
                    </div>
                    <div>
                      <div>
                        <div className="excerpt"
                          style={{
                            display: "block",
                            "text-align": "right",
                            "font-size": "14px",
                          }}
                        >
                          <img src="/sites/all/themes/hmm/images/blogs/date-gray.png"
                            style={{width: '16px'}}
                          /> Nov 17, 2017
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <img src="/sites/all/themes/hmm/images/blogs/2.jpg"
                    style={{
                      width: "100%",
                      "margin-top": "20px",
                      "margin-bottom": "20px",
                    }}
                />
                <div className="sharethis-inline-share-buttons"></div>
                <div>
                  <p>More than 50 million Americans with low or no credit scores cannot access credit from mainstream lenders. Regulatory restrictions on pay-day lending further limit their options. Happy Mango has developed off-the-shelf consumer products as well as the tools to help lenders quickly capture this market opportunity and prudently expand their customer base.</p>
                  <p>
                    <strong>Profit from both sides of the balance sheet </strong>
                  </p>
                  <p>Assuming 16% APR on unsecured loans and 8% APR on credit builder loans, a full-time loan officer can process 6,000-8,000 applications a year (24 to 32 per day) and produce $300K-$400K in revenue net of loss and processing costs in a year. With credit building products generating the deposits to fund the loans, funding costs will be minimal even as interest rates rise.</p>
                  <p>
                    <strong>Minimize defaults with Cash-Flow-Based Underwriting</strong>
                  </p>
                  <p>Using continuously updated financial account data, Happy Mango predicts borrowers’ cash flows and detects adverse changes in financial conditions before they result in late payments. Lenders can align repayment schedule with borrower cash flows and proactively reach out to borrowers at risk. With a borrower base that is 30% no-score and 70% with average FICO of 590, Happy Mango's charge-off rate is less than 5%. </p>
                  <p>
                    <strong>Cut time and costs with digitalized processes</strong>
                  </p>
                  <p>A customizable mobile and desk-top consumer interface lets lenders’ customers conveniently access the products. Lenders process applications and manage loans from a web-based portal, with automated credit analytics and customer communications. Documentation and signing from application to closing are digitalized. Automatic ACH payment option further improves efficiency and reduces delinquency. &nbsp;&nbsp;</p>
                  <p>
                    <strong>No set-up fees. No integration. No need for staff training. Terminate anytime. </strong>
                  </p>
                  <p>
                    <strong>Are you ready to start? </strong>
                  </p>
                  <p>How we can help: <a href="https://calendly.com/kate-hao_happymango/happy-mango-30-minute-online-demo" className="reqbtn">Request Demo</a>
                  </p>
                  <p>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>










      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  </>
)
